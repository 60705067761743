import React from 'react';
import SearchBar from '../SearchBar';
import './Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import loginIcon from '../assets/login.png'; // Ensure this path is correct

const Header = ({ setSupertype, setType, resetListings }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogoClick = (e) => {
        e.preventDefault();
        if (location.pathname !== '/') {
            navigate('/');
        } else {
            setSupertype('');
            setType('');
            resetListings();
        }
    };

    const handleSupertypeClick = (supertype) => {
        navigate(`/${supertype.toLowerCase()}`);
    };

    return (
        <header className="header">
            <a href="/" className="logo" onClick={handleLogoClick}>
                <span className="full-logo">gradies</span>
                <span className="mobile-logo">g</span>
            </a>
            <SearchBar />
            <nav className="nav">
                <button onClick={() => handleSupertypeClick('Pokemon')}>Pokemon</button>
                <button onClick={() => handleSupertypeClick('Trainer')}>Trainers</button>
                <button onClick={() => handleSupertypeClick('Energy')}>Energy</button>
                <a href="/profile"><i className="fa fa-user-circle"></i></a>
                <img src={loginIcon} alt="Login" className="login-icon" />
            </nav>
        </header>
    );
};

export default Header;
