import React from 'react';
import './OrderActionsModal.css';

const OrderActionsModal = ({
    onConfirm,
    onFulfill,
    trackingNumber,
    setTrackingNumber,
    orderConfirmationId,
    orderFulfillmentId,
}) => {
    // Determine button states based on the confirmation and fulfillment IDs
    const isConfirmDisabled = orderConfirmationId != null; // Disable if order is already confirmed
    const isFulfillDisabled = orderConfirmationId == null || orderFulfillmentId != null; // Enable if confirmed but not fulfilled

    return (
        <div className="order-actions-panel">
            <h2 className="modal-header">Order Actions</h2>
            <div className="action-buttons">
                <button
                    onClick={onConfirm}
                    className={`confirm-button ${isConfirmDisabled ? 'disabled' : ''}`}
                    disabled={isConfirmDisabled}
                >
                    Confirm Order
                </button>
            </div>
            <div className="tracking-section">
                <button
                    onClick={onFulfill}
                    className={`fulfill-button ${isFulfillDisabled ? 'disabled' : ''}`}
                    disabled={isFulfillDisabled}
                >
                    Fulfill Order
                </button>
                <input
                    type="text"
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    placeholder="Enter tracking number"
                    className="tracking-input"
                />
            </div>
        </div>
    );
};

export default OrderActionsModal;
