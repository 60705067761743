import React from 'react';
import './PostPurchaseModal.css';

const PostPurchaseModal = ({ onConfirm }) => {
    return (
        <div className="modal-overlay">
            <div className="post-purchase-modal">
                <h2>Transaction Completed!</h2>
                <p>Your order has been successfully placed.</p>
                <button onClick={onConfirm}>View Order</button>
            </div>
        </div>
    );
};

export default PostPurchaseModal;
