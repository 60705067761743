// src/pages/ListingsPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // Import useLocation
import Header from '../components/Header';
import Layout from '../components/Layout';
import Binder from '../components/Binder'; // Import the Binder component
import EnergySymbols from '../components/EnergySymbols'; // Import EnergySymbols
import './ListingsPage.css';
import '../styles/shared.css';


const ListingsPage = ({ supertype }) => {
    const { name } = useParams();
    const location = useLocation(); // Get the current location
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [type, setType] = useState(null); // Add state for type
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchListings = async () => {
            try {
                let url = `${apiBaseUrl}/listings`;
                if (type) {
                    url += `?type=${type}`;
                } else if (supertype) {
                    url += `?supertype=${supertype}`;
                } else if (name) {
                    const artistName = name.replace(/-/g, ' ');
                    url += `?artistName=${artistName}`;
                }

                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include', // Ensures cookies are sent with cross-origin requests
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }

                const result = await response.json();
                setListings(result.listings || []);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchListings();
    }, [type, supertype, name]); // Add type and supertype to the dependency array

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading listings: {error}</p>;

    return (
        <Layout>
            <Header setSupertype={setType} setType={setType} resetListings={() => {}} />
            {location.pathname === '/pokemon' && (
                <EnergySymbols setType={setType} />
            )} {/* Conditionally render EnergySymbols only on /pokemon */}
            <div className={`listings-page ${supertype !== 'pokemon' && type !== 'pokemon' ? 'listings-page-no-energy' : ''}`}>
                {/* Replace the card container with the Binder component */}
                <Binder listings={listings} />
            </div>
        </Layout>
    );
};

export default ListingsPage;
