// Binder.js
import React from 'react';
import BinderPage from '../BinderPage'; // If you need to use it separately
import './Binder.css';

const Binder = ({ listings }) => {
    const pages = [];
    for (let i = 0; i < listings.length; i += 4) {
        pages.push(
            <BinderPage key={i} listings={listings.slice(i, i + 4)} />
        );
    }

    return (
        <div className="binder">
            {pages}
        </div>
    );
};

export default Binder;
