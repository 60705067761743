// src/components/EnergySymbols.js

import React from 'react';
import './EnergySymbols.css';

const energyTypes = [
    { name: 'Colorless', url: 'https://gradies.blob.core.windows.net/energy-symbols/colorless.png' },
    { name: 'Darkness', url: 'https://gradies.blob.core.windows.net/energy-symbols/darkness.png' },
    { name: 'Dragon', url: 'https://gradies.blob.core.windows.net/energy-symbols/dragon.png' },
    { name: 'Lightning', url: 'https://gradies.blob.core.windows.net/energy-symbols/lightning.png' },
    { name: 'Fairy', url: 'https://gradies.blob.core.windows.net/energy-symbols/fairy.png' },
    { name: 'Fighting', url: 'https://gradies.blob.core.windows.net/energy-symbols/fighting.png' },
    { name: 'Fire', url: 'https://gradies.blob.core.windows.net/energy-symbols/fire.png' },
    { name: 'Grass', url: 'https://gradies.blob.core.windows.net/energy-symbols/grass.png' },
    { name: 'Psychic', url: 'https://gradies.blob.core.windows.net/energy-symbols/psychic.png' },
    { name: 'Metal', url: 'https://gradies.blob.core.windows.net/energy-symbols/metal.png' },
    { name: 'Water', url: 'https://gradies.blob.core.windows.net/energy-symbols/water.png' },
];

const EnergySymbols = ({ setType }) => {
    return (
        <div className="energy-symbols">
            {energyTypes.map((energy) => (
                <img
                    key={energy.name}
                    src={energy.url}
                    alt={energy.name}
                    className="energy-icon"
                    onClick={() => setType(energy.name)}  // Set the type to the selected energy type
                />
            ))}
        </div>
    );
};

export default EnergySymbols;
