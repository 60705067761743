import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BrokerSidebar from '../components/BrokerSidebar';
import BrokerContent from '../components/BrokerContent';
import Header from '../../components/Header';
import './BrokerDashboard.css';

const BrokerDashboard = ({ setSupertype, setType, resetListings }) => {
    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState('PSA Orders'); // Default to 'Orders'
    const [certNumber, setCertNumber] = useState('');

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
    };

    useEffect(() => {
        if (location.state && location.state.section === 'PSA Orders') {
            setSelectedSection('PSA Orders');
            setCertNumber(location.state.certNumber);
        }
    }, [location.state]);

    return (
        <div className="broker-dashboard">
            <Header setSupertype={setSupertype} setType={setType} resetListings={resetListings} />
            <div className="broker-main">
                <BrokerSidebar onSectionSelect={handleSectionSelect} />
                <BrokerContent selectedSection={selectedSection} certNumber={certNumber} />
            </div>
        </div>
    );
};

export default BrokerDashboard;
