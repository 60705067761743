import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import AdminContent from '../components/AdminContent';
import Header from '../../components/Header';
import './AdminDashboard.css';

const AdminDashboard = ({ setSupertype, setType, resetListings }) => {
    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState('Brokers');
    const [certNumber, setCertNumber] = useState('');

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
    };

    useEffect(() => {
        if (location.state && location.state.section === 'Certs') {
            setSelectedSection('Certs');
            setCertNumber(location.state.certNumber);
        }
    }, [location.state]);

    return (
        <div className="admin-dashboard">
            <Header setSupertype={setSupertype} setType={setType} resetListings={resetListings} />
            <div className="admin-main">
                <AdminSidebar onSectionSelect={handleSectionSelect} />
                <AdminContent selectedSection={selectedSection} certNumber={certNumber} />
            </div>
        </div>
    );
};

export default AdminDashboard;
