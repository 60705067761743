import React from 'react';
import './AdminSidebar.css';

const AdminSidebar = ({ onSectionSelect }) => {
    const sections = ['Brokers', 'Cards', 'Listings', 'Orders', 'Users', 'Certs', 'Sets'];

    return (
        <div className="admin-sidebar">
            <ul>
                {sections.map((section) => (
                    <li key={section} onClick={() => onSectionSelect(section)}>
                        {section}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminSidebar;
