import React from 'react';
import './SearchBar.css';

const SearchBar = () => {
    return (
        <div className="search-bar">
            <input type="text" placeholder="Search by Pokemon, Card Name, or PSA Number" />
            <button className="search-button"><i className="fa fa-search"></i></button>
            <button className="scan-button"><i className="fa fa-camera"></i></button>
        </div>
    );
};

export default SearchBar;
