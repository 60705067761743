// HomePage.js
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Binder from '../components/Binder';
import './HomePage.css';

const HomePage = ({ setSupertype, setType, resetListings }) => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [supertype, setSupertypeState] = useState('');
    const [type, setTypeState] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(18);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchListings = async (reset = false, newSupertype = '') => {
        try {
            let url = `${apiBaseUrl}/listings?`;
            if (page !== null) {
                url += `page=${page}&`;
            }
            if (count !== null) {
                url += `count=${count}&`;
            }
            if (newSupertype || supertype) {
                url += `supertype=${newSupertype || supertype}&`;
            }
            if (type) {
                url += `type=${type}&`;
            }
            url = url.slice(0, -1); // Remove trailing '&' or '?' if exists

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const result = await response.json();
            setListings(prevListings => reset ? result.listings : [...prevListings, ...result.listings]);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchListings(true);
    }, [supertype, type]);

    useEffect(() => {
        if (page > 1) {
            fetchListings();
        }
    }, [page]);

    const handleSetSupertype = (newSupertype) => {
        setPage(1);
        setSupertypeState(newSupertype);
        setTypeState('');
        setListings([]);
        setLoading(true);
        resetListings(true, newSupertype); // Pass newSupertype to resetListings
    };

    const handleSetType = (newType) => {
        setPage(1);
        setTypeState(newType);
        setSupertypeState('');
        setListings([]);
        setLoading(true);
        resetListings(true, newType); // Pass newType to resetListings
    };

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (loading && page === 1) return <p>Loading...</p>;
    if (error) return <p>Error loading cards: {error}</p>;

    return (
        <Layout>
            <Header setSupertype={handleSetSupertype} setType={handleSetType} resetListings={fetchListings} />
            <Binder listings={listings} />
            <div className="load-more-container">
                {!loading && (
                    <button onClick={loadMore} className="load-more">See More</button>
                )}
            </div>
        </Layout>
    );
};

export default HomePage;
