// src/components/BinderPage.js

import React from 'react';
import Card from '../Card';
import './BinderPage.css';

const BinderPage = ({ listings }) => {
    const getTypeClass = (type) => {
        if (typeof type !== 'string') return ''; // Ensure type is a string
        switch (type.toLowerCase()) {
            case 'darkness':
                return 'binder-page-darkness';
            case 'dragon':
                return 'binder-page-dragon';
            case 'fairy':
                return 'binder-page-fairy';
            case 'fighting':
                return 'binder-page-fighting';
            case 'fire':
                return 'binder-page-fire';
            case 'grass':
                return 'binder-page-grass';
            case 'lightning':
                return 'binder-page-lightning';
            case 'metal':
                return 'binder-page-metal';
            case 'psychic':
                return 'binder-page-psychic';
            case 'water':
                return 'binder-page-water';
            case 'colorless':
                return 'binder-page-colorless';
            default:
                return 'binder-page-default'; // Use default binder-page styling
        }
    };

    const firstCardType = listings[0]?.cert?.card?.types?.[0]?.type_name || 'default';
    const typeClass = getTypeClass(firstCardType);

    return (
        <div className={`binder-page ${typeClass}`}>
            <div className="binder-grid">
                {listings.map((listing, index) => (
                    <Card key={index} card={listing.cert} price={listing.price} />
                ))}
            </div>
        </div>
    );
};

export default BinderPage;
