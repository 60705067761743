// Card.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Card.css';

const Card = ({ card, price }) => {
    const navigate = useNavigate();
    const certNumber = card?.cert_number;

    const handleClick = () => {
        navigate(`/listings/${certNumber}`);
    };

    const frontImageUrl = card?.cert_images?.front_image_url || 'https://via.placeholder.com/200';
    const grade = card?.grade || 'N/A';
    const name = card?.card?.name || 'Unknown';
    const set = card?.card?.set?.name || 'Unknown Set';
    const finalPrice = Math.ceil(price * 1.1); // Adjust based on your pricing logic

    return (
        <div className="card" onClick={handleClick}>
            <img src={frontImageUrl} alt={name} />
            <div className="card-details">
                <div className="card-name-set">
                    <strong>{name}</strong><br />
                    <span className="card-set">{set}</span>
                </div>
                <div className="card-grade-price">
                    <strong>{grade}</strong><br />
                    <span className="card-price">${finalPrice.toLocaleString()}</span>
                </div>
            </div>
        </div>
    );
};

export default Card;
